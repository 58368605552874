<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.intf.interface.title.editHeadTitle') : $t('cip.plat.intf.interface.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
    <el-tabs style="background-color: #ffffff" v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="$t('cip.plat.intf.interface.field.paramFormat')" name="first">
        <el-form style="background-color:#fff;" label-width="150px">
          <el-form-item :label="$t('cip.plat.intf.interface.field.inputParamFormat')">
            <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 20}" v-model="dataForm.inputParamFormat"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cip.plat.intf.interface.field.outputFormat')">
            <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 20}" v-model="dataForm.outputFormat"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('cip.plat.intf.interface.field.serviceOutput')" name="third">
        <el-main>
          <el-row>
            <el-col>
              <head-layout
                :head-btn-options="subTableheadBtnOptions"
                :head-title="$t(`cip.plat.intf.interface.title.headCateTitle`)"
                @head-remove="headRemove"
                @head-add-tabs="headAddTabs"
              ></head-layout>
            </el-col>
          </el-row>
          <grid-layout
            ref="gridLayOut"
            class="faceList"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @row-remove="tableDel"
            @gird-handle-select-click="selectionChange"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
            @page-refresh-change="onLoad"
          >
          </grid-layout>
        </el-main>
      </el-tab-pane>
    </el-tabs>
    <CommonDialog v-if="showTreeDialog" class="qmDialog" width="60%" :dialogTitle="$t(`cip.plat.intf.interface.title.headCateTitle`)" @cancel="showTreeDialog = false" @confirm="confirmSubtable">
      <intfServiceType ref="serviceType" class="serviceType" :type="flag"></intfServiceType>
    </CommonDialog>
  </div>
</template>
<script>
import GridLayout from "@/views/components/layout/grid-layout";
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {remove, saveOrUpdate as update, selectById as getDetail} from "@/api/system/interface";
import { list } from '@/api/system/intfServiceType'
import intfServiceType from '../intfServiceType/index'
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "interfaceListEdit",
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    intfServiceType,
    CommonDialog
  },
  data() {
    return {
      flag:"button",
      selectionList:[],
      activeName: 'first',
      viewFlag:false,
      tableData: [],
      type: 'view',
      showTreeDialog:false,
      dataForm: {
        outputName: '',
      },
      tableLoading: false,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
    }
  },
  computed: {
    gridRowBtn() {
      return [
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.intf.interface.field.name'),
            prop: 'name',
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.code'),
            prop: 'code',
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.ip'),
            prop: 'ip',
            align: "center",
            overHidden: true
          },
          {
            label: this.$t('cip.plat.intf.interface.field.port'),
            prop: 'port',
            align: "center",
            overHidden: true,
          },
        ],
      }
    },
    subTableheadBtnOptions() {
      return [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.intf.interface.field.code'),
          prop: 'code',
          labelWidth: 130,
          disabled: this.viewFlag,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.intf.interface.field.code`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.interface.field.code'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.intf.interface.field.name'),
          prop: 'name',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.intf.interface.field.name`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.interface.field.name'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.intf.interface.field.status'),
          prop: 'status',
          labelWidth: 130,
          span: 8,
          type: 'select',
          dataType: "string",
          disabled: !['edit','add'].includes(this.type),
          dicUrl: `${baseUrl}yes_no`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.intf.interface.field.status'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.intf.interface.field.isRetry'),
          prop: 'isRetry',
          labelWidth: 130,
          span: 8,
          type: 'select',
          dataType: "string",
          disabled: !['edit','add'].includes(this.type),
          dicUrl: `${baseUrl}yes_no`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        },
        {
          label: this.$t('cip.plat.intf.interface.field.maxRetryCount'),
          prop: 'maxRetryCount',
          labelWidth: 130,
          span: 8,
          type: 'number',
          minRows:0,
          maxRows:100,
          placeholder: this.$t(`cip.plat.intf.interface.field.maxRetryCount`),
          readonly: !['add', 'edit'].includes(this.type),
        },
        {
          label: this.$t('cip.plat.intf.interface.field.timeout'),
          prop: 'timeout',
          labelWidth: 130,
          type: 'number',
          span: 8,
          minRows:0,
          maxRows:1000000000,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.intf.interface.field.timeout"),
              trigger: "blur"
            },
            {
              type: 'number',
              min: 0,
              max: 1000000000,
              message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.cmn.rule.ten`),
              trigger: "blur",
            }
            ]
        },
        {
          label: this.$t('cip.plat.intf.interface.field.devName'),
          prop: 'inputName',
          labelWidth: 130,
          type: 'select',
          props: {
            label: 'name',
            value: 'code'
          },
          dicUrl: '/api/sinoma-interface/serviceType/list?type=1',
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.intf.interface.field.devName`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.interface.field.devName'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.intf.interface.field.devUrl'),
          prop: 'inputUrl',
          labelWidth: 130,
          span: 8,
          maxlength: 200,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.intf.interface.field.devUrl`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.interface.field.devUrl'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.intf.interface.field.inputMethodName'),
          prop: 'inputMethodName',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.intf.interface.field.inputMethodName`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.interface.field.inputMethodName'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.sys.dataInterface.field.requestMethod'),
          prop: 'requestMethod',
          type: "select",
          dicData:[{
            label:'GET',
            value: 'GET'
          },{
            label: 'POST',
            value: 'POST'
          },{
            label: 'DELETE',
            value: 'DELETE'
          }],
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.sys.dataInterface.field.requestMethod`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.interface.field.requestMethod'),
              trigger: "change"
            },
          ],
        },
        {
          label: this.$t('cip.plat.intf.interface.field.serviceType'),
          prop: 'serviceTypeId',
          labelWidth: 130,
          type: 'tree',
          props: {
            label: 'name',
            value: 'id'
          },
          dicUrl: '/api/sinoma-interface/serviceType/tree',
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.intf.interface.field.outputName`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.interface.field.outputName'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },

      ]
    },
  },
  created() {
    let {id, type,inputName,interfaceId} = this.$route.query;
    this.type = type;
    this.viewFlag= type=='edit'
    this.dataForm.inputName = inputName
    this.dataForm.serviceTypeId = interfaceId
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  watch:{
    tableData(newArr,oldArr){
      this.dataForm.outputName = newArr.map(item => item.code).join(',')
    }
  },
  methods: {
      tableDel(row){
      let arr = this.tableData.filter(item => item.id != row.id)
        this.tableData = arr
      console.log(this.tableData)
    },
    confirmSubtable(){
      let arr = this.$refs.serviceType.selectionList
      this.tableData = arr
      this.showTreeDialog = false
      console.log(arr)
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log("aaa", this.selectionList)
    },
    headAddTabs(){
      this.showTreeDialog = true
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          let ids=this.selectionList.map(item=>{return item.id})
          let arr = this.tableData.filter(item => !ids.includes(item.id))
          console.log(ids,arr)
          this.tableData = arr
        })
    },
    handleClick(){

    },
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
        list({"code":this.dataForm.outputName == '' ? 'undefined': this.dataForm.outputName}).then(res => {
          this.tableData = res.data.data
        })
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading();
          if(this.$refs.formLayout.dataForm.outputName instanceof Array){
            this.$refs.formLayout.dataForm.outputName = this.$refs.formLayout.dataForm.outputName.join(',')
          }
          update(this.$refs.formLayout.dataForm)
            .then(res => {
              const {msg, data} = res.data;
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .faceList .avue-crud .el-table {
  height: calc(100vh - 530px) !important;
  max-height: calc(100vh - 530px) !important;
}
</style>
